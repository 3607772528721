import * as React from 'react';
import { useRouter } from 'next/router';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {
  Box,
  Drawer,
  CssBaseline,
  List,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Menu,
  Collapse,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { oauthServices } from '@services';
import {
  GlobalSearch,
  Image,
  LanguageSwitcher,
  Link,
  Logo,
  useFlags,
  NotificationsDrawer,
  PermissionsGate,
  TenantSelection,
} from '@components';
import {
  usePermissions,
  useConsolidateStoreOperations,
  useNotificationsOperations,
  useDevice,
} from '@hooks';

import { showError } from '@utils';
import getRoutes from './getRoutes';

const drawerWidth = 200;
const topbarHeight = '40px';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  maxHeight: '40px',
  padding: theme.spacing(0, 2),
  ...(open && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const DrawerLink = React.forwardRef((props, ref) => (
  <Link customRef={ref} {...props} />
));

const DrawerLayout = ({ children }) => {
  const theme = useTheme();
  const { pathname } = useRouter();
  const { isMobile } = useDevice();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const { findEntity } = usePermissions();
  useConsolidateStoreOperations(true);
  const { isReturnInboxEnabled } = useFlags();
  useNotificationsOperations(true);

  const routes = React.useMemo(
    () => getRoutes(findEntity, isReturnInboxEnabled),
    [findEntity, isReturnInboxEnabled]
  );

  const _handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const _logOut = async () => {
    try {
      await oauthServices.logOut();
    } catch (error) {
      showError(error);
    }
  };

  const _handleClose = async () => {
    setAnchorEl(null);
  };

  const _handleDrawerOpen = () => {
    setOpen(true);
  };

  const _handleDrawerClose = () => {
    setOpen(false);
  };

  const _handleClick = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex(null);
    } else {
      setSelectedIndex(index);
      localStorage.setItem('selectedIndex', index);
    }
  };

  const _activeRoute = (route) => {
    return (
      (pathname.indexOf(route) > -1 && route !== '/') || pathname === route
    );
  };

  React.useEffect(() => {
    routes.forEach((route) => {
      if (route.url) {
        if (pathname.includes(route.url)) {
          localStorage.setItem('selectedIndex', routes.indexOf(route));
          setSelectedIndex(routes.indexOf(route));
        }
      }
      if (route.options) {
        route.options.forEach((option) => {
          if (pathname.includes(option.url)) {
            localStorage.setItem('selectedIndex', routes.indexOf(route));
            setSelectedIndex(routes.indexOf(route));
          }
        });
      }
    });
  }, [pathname, routes]);

  const drawer = (
    <>
      <DrawerHeader
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px 0',
          display: { xs: 'flex', md: 'none' },
        }}
      >
        <Box sx={{ height: '100%', marginLeft: '16px' }}>
          <Logo />
        </Box>
        <IconButton onClick={_handleDrawerClose}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      {isMobile && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 16px 16px',
          }}
        >
          <TenantSelection />
          <LanguageSwitcher />
        </Box>
      )}
      {routes.map((route, index) => {
        if (route?.hide) return;
        return (
          <PermissionsGate
            key={route.title}
            disabled={route?.disablePermissionGate}
            entity={route?.entity}
          >
            <List sx={{ marginBottom: '16px' }}>
              <ListItem
                component={route?.url ? DrawerLink : Box}
                href={route?.url}
                isdrawer="true"
                disablePadding
                onClick={() => _handleClick(index)}
              >
                <ListItemButton
                  selected={_activeRoute(route?.url)}
                  className={index === selectedIndex ? 'list-item-open' : ''}
                  sx={{
                    gap: '16px',
                    color: theme?.palette?.text?.gray,
                    '&.list-item-open': {
                      backgroundColor: theme.palette.lightGray.main,
                      padding: '7px 16px',
                    },
                    '&.Mui-selected': {
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.backgroundGray.default,
                      ':hover': {
                        backgroundColor: theme.palette.backgroundGray.default,
                      },
                    },
                    ':hover': {
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.backgroundGray.default,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'inline-flex',
                    }}
                  >
                    <Image
                      src={`/icons/drawer/${route?.icon}`}
                      fill
                      wrapperStyle={{ width: '24px', height: '24px' }}
                    />
                  </Box>
                  {route.url ? (
                    <ListItemText
                      primary={route?.title}
                      sx={{
                        margin: 0,
                        '.MuiListItemText-primary': {
                          fontSize: '0.875rem',
                          fontWeight: '500',
                        },
                      }}
                    />
                  ) : (
                    <ListItemText
                      primary={route?.title}
                      sx={{
                        margin: 0,
                        '.MuiListItemText-primary': {
                          fontSize: '0.875rem',
                          fontWeight: '500',
                        },
                      }}
                    />
                  )}
                  {route?.options?.length &&
                    (index === selectedIndex ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
              </ListItem>
              <Collapse
                in={index === selectedIndex}
                timeout="auto"
                unmountOnExit
              >
                {route?.options && (
                  <List>
                    {route?.options?.map((opt) => {
                      if (opt?.hide) return;
                      return (
                        <PermissionsGate
                          key={opt.title}
                          disabled={opt.disablePermissionGate}
                          entity={opt?.entity}
                        >
                          <ListItem
                            component={DrawerLink}
                            href={opt?.url}
                            disablePadding
                            isdrawer="true"
                          >
                            <ListItemButton
                              disabled={opt?.url === '#0'}
                              selected={_activeRoute(opt?.url)}
                              sx={{
                                paddingLeft: '57px',
                                color: theme.palette.darkGray.main,
                                backgroundColor: theme.palette.lightGray.main,
                                '&::before': {
                                  content: '""',
                                  position: 'absolute',
                                  left: '0',
                                  width: '10px',
                                  height: '100%',
                                  backgroundColor: theme.palette.secondary.main,
                                },
                                '&.Mui-selected': {
                                  color: theme.palette.primary.main,
                                  backgroundColor:
                                    theme.palette.backgroundGray.default,
                                  ':hover': {
                                    backgroundColor:
                                      theme.palette.backgroundGray.default,
                                  },
                                  '&::before': {
                                    backgroundColor: theme.palette.primary.main,
                                  },
                                },
                                '&.Mui-disabled': {
                                  backgroundColor: theme.palette.lightGray.main,
                                  opacity: '1',
                                  '.MuiListItemText-root': {
                                    opacity: '0.38',
                                  },
                                  '&::before': {
                                    content: 'none',
                                  },
                                },
                                ':hover': {
                                  backgroundColor:
                                    theme.palette.backgroundGray.default,
                                },
                              }}
                            >
                              <ListItemText
                                primary={opt?.title}
                                sx={{
                                  margin: 0,
                                  flex: 'unset',
                                  '.MuiListItemText-primary': {
                                    fontSize: '0.875rem',
                                    fontWeight: '500',
                                  },
                                }}
                              />
                            </ListItemButton>
                          </ListItem>
                        </PermissionsGate>
                      );
                    })}
                  </List>
                )}
              </Collapse>
            </List>
          </PermissionsGate>
        );
      })}
    </>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        color="background"
        sx={{
          '&.MuiAppBar-colorTransparent': { boxShadow: 'none' },
          boxShadow: 'none',
          borderBottom: `1px solid ${theme.palette.separator}`,
          backgroundColor: theme.palette.lightGray.main,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            marginLeft: 0,
            '&.MuiToolbar-root': {
              minHeight: 'unset',
              height: topbarHeight,
              padding: 0,
            },
          }}
        >
          <Box
            sx={{
              height: '100%',
              width: '298px',
              display: { xs: 'none', md: 'block' },
            }}
          >
            <Logo />
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="secondary"
            aria-label="menu"
            onClick={_handleDrawerOpen}
            sx={{
              display: { md: 'none' },
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <PermissionsGate>
            <GlobalSearch />
          </PermissionsGate>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: '10px' }}>
                  <TenantSelection />
                  <LanguageSwitcher />
                </Box>
                <NotificationsDrawer />
                <Box sx={{ cursor: 'pointer' }} onClick={_handleMenu}>
                  <Image
                    src="/images/profile.svg"
                    fill
                    wrapperStyle={{ width: '24px', height: '24px' }}
                  />
                </Box>
              </Box>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={_handleClose}
                sx={{ top: 24, padding: 0 }}
              >
                <MenuItem
                  sx={{
                    padding: '12px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '20px',
                  }}
                  onClick={_logOut}
                >
                  <ListItemText>Logout</ListItemText>
                  <Image
                    src="/icons/logout.svg"
                    fill
                    wrapperStyle={{ width: '20px', height: '20px' }}
                  />
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          flexShrink: 0,
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            width: `calc(${drawerWidth}px + 20px)`,
            boxSizing: 'border-box',
          },
        }}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={(_, reason) =>
          reason === 'backdropClick' && _handleDrawerClose()
        }
      >
        {drawer}
      </Drawer>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': {
            marginTop: `calc(${topbarHeight} - 1px)`,
            height: `calc(100% - ${topbarHeight})`,
            paddingTop: '16px',
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: theme.palette.white.paper,
            borderRight: `1px solid ${theme.palette.separator}`,
          },
        }}
        variant="permanent"
        anchor="left"
        open={open}
      >
        {drawer}
      </Drawer>
      <Main
        open={open}
        sx={{
          padding: 0,
          marginTop: topbarHeight,
          width: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <Box>{children !== null ? children : null}</Box>
      </Main>
    </Box>
  );
};

const memoizedDrawerLayout = React.memo(DrawerLayout);
export { memoizedDrawerLayout as DrawerLayout };
